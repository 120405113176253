import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, CreateAxiosDefaults, InternalAxiosRequestConfig } from 'axios';


export type HttpOption = {
    responseInterceptors?: Array<(value: AxiosResponse) => AxiosResponse>;
    requestInterceptors?: Array<(value: InternalAxiosRequestConfig) => InternalAxiosRequestConfig>;
}

// 对并发请求进行处理

export class Http {
    private instance: AxiosInstance;
    private peddingMap: Map<string, Array<[(data: any) => void, (e: any) => void]>> = new Map();
    constructor(option: CreateAxiosDefaults & HttpOption) {
        this.instance = axios.create({
          ...option,
          timeout: 300000,
        });
        (option.responseInterceptors || []).forEach(handler => {
            this.instance.interceptors.response.use(handler, error => handler(error.response));
        });
        (option.requestInterceptors || []).forEach(handler => {
            this.instance.interceptors.request.use(handler);
        });
    }

    private static getAbsoluteURI<T>(option: AxiosRequestConfig<T>) {
        return `${option.url}_${JSON.stringify(option.data)}_${JSON.stringify(option.params)}`;
    }
    // 同一时刻同样的请求并且参数一致，只能允许发送一次
    public async request<R, T = unknown>(option: AxiosRequestConfig<T>) {
        const key = Http.getAbsoluteURI(option);
        if (!this.peddingMap.get(key)) this.peddingMap.set(key, []);
        const tasks = this.peddingMap.get(key) || [];

        const promise = new Promise<R>((resolve, reject) => {
            tasks.push([resolve, reject]);
        });

        const run = <T>(info: T, index: 0 | 1) => {
            while (tasks.length) {
                const cur = tasks.shift();
                cur && cur[index](index === 1 ? info : JSON.parse(JSON.stringify(info || null)));
            }
            this.peddingMap.delete(key);
        };

        if ((this.peddingMap.get(key) || []).length === 1) {
            this.instance.request<T, AxiosResponse<R>>(option).then(info => {
                run(info.data, 0);
            }).catch(e => {
                run(e, 1);
            });
        }

        return promise;
    }
    public get<R, T = unknown>(url: string, params?: T, headers?: AxiosRequestConfig<unknown>['headers']) {
        return this.request<R, T>({
            url,
            method: 'GET',
            params,
            headers,
        });
    }
    public post<R = void, T = unknown>(url: string, params?: T, headers?: AxiosRequestConfig<unknown>['headers']) {
        return this.request<R, T>({
            url,
            method: 'POST',
            data: params,
            headers,
        });
    }
    public put<R, T = unknown>(url: string, params?: T) {
        return this.request<R, T>({
            url,
            method: 'PUT',
            params,
        });
    }
    public delete<R, T = unknown>(url: string, params?: T) {
        return this.request<R, T>({
            url,
            method: 'DELETE',
            params,
        });
    }
}

export default Http;
