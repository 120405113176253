import "core-js/modules/es.array.push.js";
import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
  class: "NavBar"
};
const _hoisted_2 = {
  class: "top"
};
const _hoisted_3 = {
  class: "right"
};
const _hoisted_4 = {
  class: "user"
};
const _hoisted_5 = {
  class: "name"
};
const _hoisted_6 = {
  class: "menu"
};
const _hoisted_7 = {
  class: "more"
};
const _hoisted_8 = {
  class: "bar"
};
const _hoisted_9 = ["onClick"];
import { computed, h, unref } from 'vue';
import { useUserStore } from '@/store/user';
import { NIcon } from 'naive-ui';
import { LogOutOutline as LogoutIcon, Apps as AppIcon } from '@vicons/ionicons5';
import cookie, { CookitMangerEnum } from '@/utils/cookie';
import { children } from '@/router';
import { useRoute, useRouter } from 'vue-router';
export default /*@__PURE__*/_defineComponent({
  __name: 'NavBar',
  setup(__props) {
    const router = useRouter();
    const route = useRoute();
    const {
      userInfo,
      tenantList
    } = useUserStore();
    const authRoutes = computed(() => children.filter(item => item.meta.hidden !== true));
    const curRouteName = computed(() => String(route.name));
    function renderIcon(icon) {
      return () => {
        return h(NIcon, null, {
          default: () => h(icon)
        });
      };
    }
    const options = [{
      label: '退出登录',
      key: 'logout',
      icon: renderIcon(LogoutIcon)
    }];
    const moreOpts = computed(() => {
      return unref(tenantList).map(tenant => {
        return {
          label: tenant.name,
          key: tenant.id
        };
      });
    });
    const toCurrent = name => {
      router.push({
        name
      });
    };
    // 选择页面
    const onSelect = value => {
      if (value === 'logout') {
        cookie.remove(CookitMangerEnum.SET_USER_TOKEN);
        cookie.remove(CookitMangerEnum.SET_TENANT_ID);
        router.replace(`/login?origin=${router.currentRoute.value.fullPath}`);
      }
    };
    // 重新载入应用
    const onPickerApp = value => {
      cookie.set(CookitMangerEnum.SET_TENANT_ID, value);
      window.location.reload();
    };
    return (_ctx, _cache) => {
      const _component_n_dropdown = _resolveComponent("n-dropdown");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_cache[2] || (_cache[2] = _createElementVNode("div", {
        class: "left"
      }, "欢迎登录", -1)), _createElementVNode("div", _hoisted_3, [_createVNode(_component_n_dropdown, {
        options: options,
        onSelect: onSelect
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_4, [_cache[0] || (_cache[0] = _createElementVNode("span", {
          class: "aiyouaiyou ay-user"
        }, null, -1)), _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(userInfo).username), 1), _cache[1] || (_cache[1] = _createElementVNode("span", {
          class: "aiyouaiyou ay-arrow-down-filling"
        }, null, -1))])]),
        _: 1
      })])]), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createVNode(_component_n_dropdown, {
        options: moreOpts.value,
        onSelect: onPickerApp
      }, {
        default: _withCtx(() => [_createVNode(_unref(AppIcon))]),
        _: 1
      }, 8, ["options"])]), _createElementVNode("div", _hoisted_8, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(authRoutes.value, route => {
        return _openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["item", {
            'active': curRouteName.value.startsWith(String(route.name))
          }]),
          onClick: $event => toCurrent(route.name),
          key: route.name
        }, _toDisplayString(route.meta.title), 11, _hoisted_9);
      }), 128))])])]);
    };
  }
});