/**
 * 预览管理端
 */

import { useSessionStorage } from '@vueuse/core';
import { nextTick, onMounted, onUnmounted, Ref, ref, unref, watch } from 'vue';

const jian_2 = 'https://computed-cdn.langtaoshuzi.com/template/default-new/jian_2.png';
const x_2 = 'https://computed-cdn.langtaoshuzi.com/template/default-new/x_2.png';
const x_1 = 'https://computed-cdn.langtaoshuzi.com/template/default-new/x_1.png';
const top_bg = 'https://computed-cdn.langtaoshuzi.com/template/default-new/top_bg.png';
const top_bg_1 = 'https://computed-cdn.langtaoshuzi.com/template/default-new/top_bg_1.png';
const t_s_2 = 'https://computed-cdn.langtaoshuzi.com/template/default-new/t_s_2.png';
const t_s_1 = 'https://computed-cdn.langtaoshuzi.com/template/default-new/t_s_1.png';
const t_l_2 = 'https://computed-cdn.langtaoshuzi.com/template/default-new/t_l_2.png';
const t_l_1 = 'https://computed-cdn.langtaoshuzi.com/template/default-new/t_l_1.png';
const t_c_2 = 'https://computed-cdn.langtaoshuzi.com/template/default-new/t_c_2.png';
const t_c_1 = 'https://computed-cdn.langtaoshuzi.com/template/default-new/t_c_1.png';
const s = 'https://computed-cdn.langtaoshuzi.com/template/default-new/s.png';
const n9_2 = 'https://computed-cdn.langtaoshuzi.com/template/default-new/n9_2.png';
const n9_1 = 'https://computed-cdn.langtaoshuzi.com/template/default-new/n9_1.png';
const n8_2 = 'https://computed-cdn.langtaoshuzi.com/template/default-new/n8_2.png';
const n8_1 = 'https://computed-cdn.langtaoshuzi.com/template/default-new/n8_1.png';
const n7_2 = 'https://computed-cdn.langtaoshuzi.com/template/default-new/n7_2.png';
const n7_1 = 'https://computed-cdn.langtaoshuzi.com/template/default-new/n7_1.png';
const n6_2 = 'https://computed-cdn.langtaoshuzi.com/template/default-new/n6_2.png';
const n6_1 = 'https://computed-cdn.langtaoshuzi.com/template/default-new/n6_1.png';
const n5_2 = 'https://computed-cdn.langtaoshuzi.com/template/default-new/n5_2.png';
const n5_1 = 'https://computed-cdn.langtaoshuzi.com/template/default-new/n5_1.png';
const n4_2 = 'https://computed-cdn.langtaoshuzi.com/template/default-new/n4_2.png';
const n4_1 = 'https://computed-cdn.langtaoshuzi.com/template/default-new/n4_1.png';
const n3_2 = 'https://computed-cdn.langtaoshuzi.com/template/default-new/n3_2.png';
const n3_1 = 'https://computed-cdn.langtaoshuzi.com/template/default-new/n3_1.png';
const n2_2 = 'https://computed-cdn.langtaoshuzi.com/template/default-new/n2_2.png';
const n2_1 = 'https://computed-cdn.langtaoshuzi.com/template/default-new/n2_1.png';
const n1_2 = 'https://computed-cdn.langtaoshuzi.com/template/default-new/n1_2.png';
const n1_1 = 'https://computed-cdn.langtaoshuzi.com/template/default-new/n1_1.png';
const n0_2 = 'https://computed-cdn.langtaoshuzi.com/template/default-new/n0_2.png';
const n0_1 = 'https://computed-cdn.langtaoshuzi.com/template/default-new/n0_1.png';
const m_2 = 'https://computed-cdn.langtaoshuzi.com/template/default-new/m_2.png';
const m_1 = 'https://computed-cdn.langtaoshuzi.com/template/default-new/m_1.png';
const jian_1 = 'https://computed-cdn.langtaoshuzi.com/template/default-new/jian_1.png';
const jia_2 = 'https://computed-cdn.langtaoshuzi.com/template/default-new/jia_2.png';
const jia_1 = 'https://computed-cdn.langtaoshuzi.com/template/default-new/jia_1.png';
const f_bg = 'https://computed-cdn.langtaoshuzi.com/template/default-new/f_bg.png';
const f_bg_1 = 'https://computed-cdn.langtaoshuzi.com/template/default-new/f_bg_1.png';
const eq_2 = 'https://computed-cdn.langtaoshuzi.com/template/default-new/eq_2.png';
const eq_1 = 'https://computed-cdn.langtaoshuzi.com/template/default-new/eq_1.png';
const delete_2 = 'https://computed-cdn.langtaoshuzi.com/template/default-new/delete_2.png';
const delete_1 = 'https://computed-cdn.langtaoshuzi.com/template/default-new/delete_1.png';
const d_2 = 'https://computed-cdn.langtaoshuzi.com/template/default-new/d_2.png';
const d_1 = 'https://computed-cdn.langtaoshuzi.com/template/default-new/d_1.png';
const chu_2 = 'https://computed-cdn.langtaoshuzi.com/template/default-new/chu_2.png';
const chu_1 = 'https://computed-cdn.langtaoshuzi.com/template/default-new/chu_1.png';
const c_2 = 'https://computed-cdn.langtaoshuzi.com/template/default-new/c_2.png';
const c_1 = 'https://computed-cdn.langtaoshuzi.com/template/default-new/c_1.png';
const bg = 'https://computed-cdn.langtaoshuzi.com/template/default-new/bg.jpg';

enum MessageEnum {
    UPDATE = 'UPDATE',
}

export interface MessageRes {
    bg: string;

    x_2: string;

    x_1: string;

    // 移动端
    top_bg: string;
    // ipad
    top_bg_1: string;

    t_s_2: string;

    t_s_1: string;

    t_l_2: string;

    t_l_1: string;

    t_c_2: string;

    t_c_1: string;

    s: string;

    n9_2: string;

    n9_1: string;

    n8_2: string;

    n8_1: string;

    n7_2: string;

    n7_1: string;

    n6_2: string;

    n6_1: string;

    n5_2: string;

    n5_1: string;

    n4_2: string;

    n4_1: string;

    n3_2: string;

    n3_1: string;

    n2_2: string;

    n2_1: string;

    n1_2: string;

    n1_1: string;

    n0_2: string;

    n0_1: string;

    m_2: string;

    m_1: string;

    jian_2: string;

    jian_1: string;

    jia_2: string;

    jia_1: string;

    f_bg: string;
    f_bg_1: string;

    eq_2: string;

    eq_1: string;

    delete_2: string;

    delete_1: string;

    d_2: string;

    d_1: string;

    chu_2: string;

    chu_1: string;

    c_2: string;

    c_1: string;
}

export interface MessageModel {
    id: number;
    pic: string;
    name: string;
    categoryId?: number;
    isVip?: number;
    twoColor: string;
    statusColor: string;
    twoBgColor: string;
    themeColor: string;
    tenantId: number;
    createAt: string;
    updateAt: string;
    status: 0 | 1;
    decoration: 0 | 1;
    resource: MessageRes;
}

interface Message {
    type: MessageEnum.UPDATE;
    payload: MessageModel;
}

/**
 * 消息发送
 */
export const usePreviewManger = (iframeRef?: Ref<HTMLIFrameElement>) => {
    const onLoaded = ref(false);
    const message = useSessionStorage<MessageModel>('usePreviewManger:message', {
        id: 0,
        pic: '',
        status: 0,
        decoration: 1,
        name: '',
        twoColor: '#FF2A5A',
        themeColor: '#FF2A5A',
        twoBgColor: '#FFCAD6',
        tenantId: 0,
        createAt: '',
        updateAt: '',
        statusColor: '#000000',
        resource: {
            f_bg_1,
            bg,
            x_2,
            x_1,
            top_bg,
            top_bg_1,
            t_s_2,
            t_s_1,
            t_l_2,
            t_l_1,
            t_c_2,
            t_c_1,
            s,
            n9_2,
            n9_1,
            n8_2,
            n8_1,
            n7_2,
            n7_1,
            n6_2,
            n6_1,
            n5_2,
            n5_1,
            n4_2,
            n4_1,
            n3_2,
            n3_1,
            n2_2,
            n2_1,
            n1_2,
            n1_1,
            n0_2,
            n0_1,
            m_2,
            m_1,
            jian_2,
            jian_1,
            jia_2,
            jia_1,
            f_bg,
            eq_2,
            eq_1,
            delete_2,
            delete_1,
            d_2,
            d_1,
            chu_2,
            chu_1,
            c_2,
            c_1,
        },
    });

    if (iframeRef) {
        // 发送消息
        const postMessage = (message: Message) => {
            try {
                // eslint-disable-next-line
                unref(iframeRef).contentWindow!.postMessage({
                    type: message.type,
                    payload: JSON.stringify(message.payload),
                });
            } catch (e) {
                console.log(e);
            }
        };

        watch(
            () => unref(message),
            (message) => {
                postMessage({
                    type: MessageEnum.UPDATE,
                    payload: message,
                });
            },
            { deep: true },
        );

        onMounted(() => {
            nextTick(() => {
                (unref(iframeRef).contentWindow as any).setLoaded = () => {
                    postMessage({
                        type: MessageEnum.UPDATE,
                        payload: unref(message),
                    });
                };
            });
        });
    } else {
        const handleMessage = (event: MessageEvent<Message>) => {
            if (event.data.type === MessageEnum.UPDATE) {
                message.value = JSON.parse(String(event.data.payload)) as MessageModel;
                onLoaded.value = true;
            }
        };
        window.addEventListener('message', handleMessage);
        onMounted(() => {
            if (!(window as any).setLoaded) {
                (window as any).setLoaded = () => {
                    onLoaded.value = true;
                };
            }
            (window as any).setLoaded();
        });
        onUnmounted(() => {
            window.removeEventListener('message', handleMessage);
        });
    }
    return {
        onLoaded,
        message,
    };
};
