import "core-js/modules/es.array.push.js";
import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "content"
};
const _hoisted_2 = {
  class: "title"
};
const _hoisted_3 = {
  class: "text"
};
const _hoisted_4 = {
  class: "right-box"
};
import { computed, h, unref } from 'vue';
import NavBar from '@/components/NavBar.vue';
// eslint-disable-next-line import/no-cycle
import { children } from '@/router';
import { NIcon, NMenu } from 'naive-ui';
import { useRoute, useRouter } from 'vue-router';
import { EnterOutline } from '@vicons/ionicons5';
import { useStorage } from '@vueuse/core';
export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {
    const collapsed = useStorage('collapsed', false);
    const route = useRoute();
    function render(icon) {
      return () => h(NIcon, null, {
        default: () => h(icon)
      });
    }
    const More = render(EnterOutline);
    const handleMapRoute = routes => {
      return routes.filter(route => !route.meta.hidden).map(route => {
        const children = handleMapRoute(route.children || []);
        return {
          key: String(route.name),
          label: route.meta.title,
          icon: route.meta.icon ? render(route.meta.icon) : undefined,
          children: children.length === 0 ? undefined : children
        };
      });
    };
    const child = computed(() => children.find(item => String(route.name).startsWith(String(item.name))));
    const menus = computed(() => {
      var _a;
      const list = ((_a = unref(child)) === null || _a === void 0 ? void 0 : _a.children) || [];
      return handleMapRoute(list);
    });
    const curRouteValue = computed(() => {
      var _a;
      return ((_a = unref(menus).find(item => String(route.name).startsWith(String(item.key)))) === null || _a === void 0 ? void 0 : _a.key) || unref(menus)[0].key;
    });
    const router = useRouter();
    const handleSelect = key => {
      // console.log('选择', opt);
      router.push(`/${key}`);
    };
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      const _component_Container = _resolveComponent("Container");
      return _openBlock(), _createBlock(_component_Container, {
        class: "home"
      }, {
        default: _withCtx(() => [_createVNode(NavBar), _createElementVNode("div", _hoisted_1, [menus.value.length > 0 ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["slide", {
            'collapsed': _unref(collapsed)
          }])
        }, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString(child.value.meta.title), 1), _createElementVNode("div", {
          class: "icon",
          onClick: _cache[0] || (_cache[0] = $event => collapsed.value = !_unref(collapsed))
        }, [_createVNode(_unref(More))])]), _createVNode(_unref(NMenu), {
          indent: 30,
          "root-indent": 10,
          collapsed: _unref(collapsed),
          "collapsed-icon-size": 22,
          options: menus.value,
          value: curRouteValue.value,
          onUpdateValue: handleSelect
        }, null, 8, ["collapsed", "options", "value"])], 2)) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_4, [_createVNode(_component_router_view)])])]),
        _: 1
      });
    };
  }
});