var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
import { defineComponent as _defineComponent, createTextVNode as _createTextVNode, createVNode as _createVNode2, Fragment as _Fragment } from 'vue';
import { unref as _unref, createVNode as _createVNode, isRef as _isRef, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { ref } from 'vue';
import { SchemaFormType } from '@/components/form/type';
import { Table, useTable } from '@/components/table';
import { getAdDictList, postAdDictAdd, postAdDictEdit, postAdDictRemove } from '@/service/api';
import { NButton, NPopconfirm, NSpace } from 'naive-ui';
import { DictEnum, getPromiseDict, resetAllDictStore } from '@/utils/dict';
import AddChildModal from './components/AddChildModal.vue';
export default /*@__PURE__*/_defineComponent({
  __name: 'DictView',
  setup(__props) {
    const addChildRecord = ref();
    const [table, data] = useTable({
      api: () => __awaiter(this, void 0, void 0, function* () {
        const info = yield getAdDictList();
        resetAllDictStore(info);
        return info;
      }),
      remove: postAdDictRemove,
      add: postAdDictAdd,
      edit: postAdDictEdit,
      tree: {
        field: 'pid',
        defaultExpandAll: true
      },
      columns: [{
        field: 'id',
        title: 'id',
        width: 160
      }, {
        field: 'pid',
        title: '父级',
        width: 150
      }, {
        field: 'type',
        title: '数据类型',
        width: 100,
        form: {
          type: SchemaFormType.SELECT,
          props: {
            api: getPromiseDict(DictEnum.DATA_TYPE)
          }
        }
      }, {
        field: 'label',
        title: '字段名称',
        form: {
          type: SchemaFormType.INPUT
        }
      }, {
        field: 'value',
        title: '字段值',
        form: {
          type: SchemaFormType.INPUT
        }
      }, {
        field: 'action',
        title: '操作',
        fixed: 'right',
        render(model, action) {
          return _createVNode2(NSpace, {
            "size": 'small'
          }, {
            default: () => [model.type ? _createVNode2(NButton, {
              "size": 'tiny',
              "type": "info",
              "onClick": () => {
                addChildRecord.value = model;
              }
            }, {
              default: () => [_createTextVNode("\u6DFB\u52A0\u4E0B\u7EA7")]
            }) : _createVNode2(_Fragment, null, null), _createVNode2(NButton, {
              "size": 'tiny',
              "type": "info",
              "onClick": () => action.edit(model)
            }, {
              default: () => [_createTextVNode("\u7F16\u8F91")]
            }), _createVNode2(NPopconfirm, {
              "on-positive-click": () => action.remove(model),
              "negative-text": "\u53D6\u6D88",
              "positive-text": "\u786E\u8BA4"
            }, {
              default: () => [_createTextVNode("\u4F60\u786E\u5B9A\u8981\u5220\u9664\uFF1F")],
              trigger: () => _createVNode2(NButton, {
                "size": 'tiny',
                "type": "error"
              }, {
                default: () => [_createTextVNode("\u5220\u9664")]
              })
            })]
          });
        }
      }]
    });
    // 新增下级
    const addChildRecordSubmit = model => __awaiter(this, void 0, void 0, function* () {
      yield postAdDictAdd(model);
      data.value = yield getAdDictList();
      addChildRecord.value = null;
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createBlock(_unref(Table), _mergeProps(_unref(table), {
        data: _unref(data),
        "onUpdate:data": _cache[1] || (_cache[1] = $event => _isRef(data) ? data.value = $event : null)
      }), {
        default: _withCtx(() => [_createVNode(AddChildModal, {
          record: addChildRecord.value,
          "onUpdate:record": _cache[0] || (_cache[0] = $event => addChildRecord.value = $event),
          submit: addChildRecordSubmit
        }, null, 8, ["record"])]),
        _: 1
      }, 16, ["data"]);
    };
  }
});