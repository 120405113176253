import Cookies from 'cookies-js';

Cookies.defaults = {
    path: '/'
};

export const CookitMangerEnum = {
    SET_TENANT_ID: 'SET_TENANT_ID',
    SET_USER_TOKEN: 'SET_USER_TOKEN',
    SET_STS_INFO: 'SET_STS_INFO',
} as const;

export default {
    set(key: string, value: string | number) {
        Cookies.set(key, value);
    },
    get(key: string) {
        return Cookies.get(key);
    },
    remove(key: string) {
        Cookies.expire(key);
    },
};