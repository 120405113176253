import { defineComponent as _defineComponent, createTextVNode as _createTextVNode, createVNode as _createVNode } from 'vue';
import { unref as _unref, isRef as _isRef, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { SchemaFormType } from '@/components/form/type';
import { Table, useTable } from '@/components/table';
import { getAdLangPage, postAdLangAdd, postAdLangEdit, postAdLangRemove } from '@/service/api';
import { DictEnum, getPromiseDict } from '@/utils/dict';
import { NButton, NPopconfirm, NSpace } from 'naive-ui';
export default /*@__PURE__*/_defineComponent({
  __name: 'LanguageView',
  setup(__props) {
    const [table, data] = useTable({
      api: getAdLangPage,
      remove: postAdLangRemove,
      add: postAdLangAdd,
      edit: postAdLangEdit,
      pagination: true,
      columns: [{
        field: 'id',
        title: 'id',
        width: 150
      }, {
        field: 'field',
        title: '标识',
        search: {
          type: SchemaFormType.SELECT,
          props: {
            clearable: true,
            api: getPromiseDict(DictEnum.LANGUAGE_TYPE)
          }
        },
        form: {
          type: SchemaFormType.SELECT,
          props: {
            api: getPromiseDict(DictEnum.LANGUAGE_TYPE)
          }
        }
      }, {
        field: 'lang',
        title: '语言',
        search: {
          type: SchemaFormType.SELECT,
          props: {
            clearable: true,
            api: getPromiseDict(DictEnum.LANG)
          }
        },
        form: {
          type: SchemaFormType.SELECT,
          props: {
            api: getPromiseDict(DictEnum.LANG)
          }
        }
      }, {
        field: 'label',
        title: '文本',
        form: {
          type: SchemaFormType.INPUT,
          props: {
            type: 'textarea'
          }
        }
      }, {
        field: 'action',
        title: '操作',
        fixed: 'right',
        render(model, action) {
          return _createVNode(NSpace, {
            "size": 'small'
          }, {
            default: () => [_createVNode(NButton, {
              "size": 'tiny',
              "type": "info",
              "onClick": () => action.edit(model)
            }, {
              default: () => [_createTextVNode("\u7F16\u8F91")]
            }), _createVNode(NPopconfirm, {
              "on-positive-click": () => action.remove(model),
              "negative-text": "\u53D6\u6D88",
              "positive-text": "\u786E\u8BA4"
            }, {
              default: () => [_createTextVNode("\u4F60\u786E\u5B9A\u8981\u5220\u9664\uFF1F")],
              trigger: () => _createVNode(NButton, {
                "size": 'tiny',
                "type": "error"
              }, {
                default: () => [_createTextVNode("\u5220\u9664")]
              })
            })]
          });
        }
      }]
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createBlock(_unref(Table), _mergeProps(_unref(table), {
        data: _unref(data),
        "onUpdate:data": _cache[0] || (_cache[0] = $event => _isRef(data) ? data.value = $event : null)
      }), null, 16, ["data"]);
    };
  }
});