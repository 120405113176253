import { createRouter, createWebHistory, RouteRecordRaw, RouterView } from 'vue-router';
import { isArray } from 'lodash-es';
import { Component } from 'vue';
import { LogoBitcoin, NewspaperOutline, PrintOutline, SadOutline, TvOutline, WalkOutline } from '@vicons/ionicons5';

import HomeIndex from '@/views/home/index.vue';
import LoginIndex from '@/views/login/index.vue';
import PreviewIndex from '@/views/preview/index.vue';
import DashboardView from '@/views/home/dashboard/DashboardView.vue';
import OrderTemplateView from '@/views/home/order/OrderTemplateView.vue';
import CategoryTemplateView from '@/views/home/template/CategoryTemplateView.vue';
import CategoryAudioView from '@/views/home/audio/CategoryAudioView.vue';
import ListAudioView from '@/views/home/audio/ListAudioView.vue';
import TemplateView from '@/views/home/template/TemplateView.vue';
import TemplateActionView from '@/views/home/template/TemplateActionView.vue';
import UserActionView from '@/views/home/user/UserActionView.vue';
import DictView from '@/views/home/system/DictView.vue';
import LanguageView from '@/views/home/system/LanguageView.vue';

export type RouteRecordRawMeta = RouteRecordRaw & {
  meta: {
    title: string;
    hidden?: boolean;
    tenantId?: string;
    fullPath?: string;
    icon?: Component,
  };
  children?: Array<RouteRecordRawMeta>;
};

const defineRoute = (routes: Array<RouteRecordRawMeta>, parent = '') => {
  routes.forEach(route => {
    route.meta.fullPath = `${parent || ''}/${route.path}`;
    if (isArray(route.children)) {
      defineRoute(route.children, route.meta.fullPath);
    } 
  });
  return routes;
};

export const children: Array<RouteRecordRawMeta> = defineRoute([
  {
    path: 'dashboard',
    name: 'dashboard',
    component: DashboardView,
    meta: {
      title: '概况',
    },
  },
  {
    path: 'template',
    name: 'template',
    component: RouterView,
    redirect: '/template/category',
    meta: {
      title: '模版管理',
      tenantId: '584589547315270',
    },
    children: [
      {
        path: 'category',
        name: 'template/category',
        component: CategoryTemplateView,
        meta: {
          icon: PrintOutline,
          title: '模版分类',
          tenantId: '584589547315270',
        },
      },
      {
        path: 'action',
        name: 'template/action',
        component: RouterView,
        redirect: '/template/action/record',
        meta: {
          icon: NewspaperOutline,
          title: '模版管理',
          tenantId: '584589547315270',
        },
        children: [
          {
            path: 'record',
            name: 'template/action/record',
            component: TemplateView,
            meta: {
              title: '模版管理',
              tenantId: '584589547315270',
              hidden: true,
            },
          },
          {
            path: 'add',
            name: 'template/action/add',
            component: TemplateActionView,
            meta: {
              title: '模版新增',
              tenantId: '584589547315270',
              hidden: true,
            },
          },
          {
            path: 'edit/:id',
            name: 'template/action/edit',
            component: TemplateActionView,
            meta: {
              title: '模版修改',
              tenantId: '584589547315270',
              hidden: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: 'audio',
    name: 'audio',
    component: RouterView,
    redirect: '/audio/category',
    meta: {
      title: '音频管理',
      tenantId: '584589547315270',
    },
    children: [
      {
        path: 'category',
        name: 'audio/category',
        component: CategoryAudioView,
        meta: {
          icon: PrintOutline,
          title: '音频分类',
          tenantId: '584589547315270',
        },
      },
      {
        path: 'list',
        name: 'audio/list',
        component: ListAudioView,
        meta: {
          icon: TvOutline,
          title: '音频列表',
          tenantId: '584589547315270',
        },
      },
    ],
  },
  {
    path: 'order',
    name: 'order',
    component: RouterView,
    redirect: '/order/template',
    meta: {
      title: '订单管理',
      tenantId: '584589547315270',
    },
    children: [
      {
        path: 'template',
        name: 'order/template',
        component: OrderTemplateView,
        meta: {
          icon: LogoBitcoin,
          title: '充值模版',
          tenantId: '584589547315270',
        },
      },
    ],
  },
  {
    path: 'user',
    name: 'user',
    component: RouterView,
    redirect: '/user/action',
    meta: {
      title: '用户管理',
      tenantId: '584589547315270',
    },
    children: [
      {
        path: 'action',
        name: 'user/action',
        component: UserActionView,
        meta: {
          title: '用户管理',
          tenantId: '584589547315270',
          icon: WalkOutline,
        },
      },
    ],
  },
  {
    path: 'system',
    name: 'system',
    component: RouterView,
    redirect: '/system/dict',
    meta: {
      title: '系统管理',
    },
    children: [
      {
        path: 'dict',
        name: 'system/dict',
        component: DictView,
        meta: {
          title: '数据碎片',
          icon: SadOutline,
        },
      },
      {
        path: 'language',
        name: 'system/language',
        component: LanguageView,
        meta: {
          title: '语言包管理',
          icon: SadOutline,
        },
      },
    ],
  },
]);

export const routes: Array<RouteRecordRawMeta> = [
  {
    path: '/',
    name: 'home',
    redirect: '/dashboard',
    component: HomeIndex,
    meta: {
      title: '首页',
      tenantId: '584589547315270',
    },
    children,
  },
  {
    path: '/login',
    name: 'login',
    component: LoginIndex,
    meta: {
      title: '登录',
      hidden: true,
    },
  },
  {
    path: '/preview/:id',
    name: 'preview',
    component: PreviewIndex,
    meta: {
      title: '模版预览',
      tenantId: '584589547315270',
    },
  },
];

const router = createRouter({
  history: createWebHistory('/'),
  routes,
});

export default router;
