import { Reactive, reactive, Ref, ref } from 'vue';
import type { TablePropSchema } from './type';

type useTable = <T extends object>(props: TablePropSchema<T>) => [
    TablePropSchema<T>,
    Ref<T[]>,
    {
        search: Reactive<T>,
        form: Reactive<T>,
    },
];

export const useTable: useTable = <T extends object>(props: TablePropSchema<T>) => {
    const search = reactive({} as T);
    const form = reactive({} as T);
    const data = ref<Array<T>>([]) as Ref<T[]>;
    return [
        Object.freeze(props),
        data,
        {
            search,
            form,
        },
    ];
};
