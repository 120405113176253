import { getAdDictList } from '@/service/api';
import { GetPromiseListResult, GetPromiseResult } from '@/types/util';

type Model = GetPromiseListResult<typeof getAdDictList>;

interface Option {
    label: string,
    value: string | number
}

// 最终数据
const store: {
    dict: Array<Model> | null;
    map: Map<string | number, Array<Option>>;
} = {
    dict: null,
    map: new Map(),
};

export enum DictEnum {
    // 数据类型
    DATA_TYPE = 'DATA_TYPE',
    // 是否是VIP支持
    IS_VIP_OPTION = 'IS_VIP_OPTION',
    // 上下架状态
    API_GET_STATUS = 'API_GET_STATUS',
    // 语言
    LANG = 'LANG',
    // 语言标识
    LANGUAGE_TYPE = 'LANGUAGE_TYPE',
    // 是否测试模版
    IS_SAND_BOX = 'IS_SAND_BOX',
    // 是否连续包月
    IS_MONTH_ALL_IN = 'IS_MONTH_ALL_IN',
    // 头部和底部存在装饰
    IS_INCLUDE_DECORATION = 'IS_INCLUDE_DECORATION',
    // 电量是否为黑色
    IS_BLACK_STATUS_BAR = 'IS_BLACK_STATUS_BAR',
}

export const resetAllDictStore = (info: GetPromiseResult<typeof getAdDictList>) => {
    store.map = new Map();
    store.dict = info || [];
    const temp = new Map<string | number, Model>();
    for (let i = 0; i < store.dict.length; i += 1) {
        const item = store.dict[i];

        if (item.pid) {
            const parent = temp.get(item.pid);
            if (!parent) {
                store.dict.splice(i, 1);
                store.dict.push(item);
                continue;
            }
            const list = store.map.get(parent.value) || [];
            if (parent.type === 'int') {
                item.value = Number(item.value) as unknown as string;
            }
            list.push(item);
            store.map.set(parent.value, list);
        } else {
            temp.set(item.id, item);
        }
    }
};

// 获取全量数据,并转换格式
export const getAllDictList = async (restore = false) => {
    try {
        if (restore) {
            store.dict = null;
            store.map = new Map();
        }
        if (store.dict) {
            return;
        }
        const info = await getAdDictList();
        resetAllDictStore(info);
        // eslint-disable-next-line
    } catch(e) {
        console.log(e);
    }
};

type GetPromiseDict = (dict: DictEnum) => () => Promise<Array<Option>>;

export const getPromiseDict: GetPromiseDict = (dict: DictEnum) => {
    return () => {
        return Promise.resolve(store.map.get(dict));
    };
};

// 字典项展示
export const renderCurrentDict = <T>(dict: DictEnum, field: keyof T) => (model: T) => {
    return String(store.map.get(dict).find(item => item.value === model[field])?.label || model[field]);
};

// 语言展示
export const getDictLang = (dict: DictEnum, lang = 'zh-Hans') => {
    console.log(store.map, dict);
    return store.map.get(dict).find(item => item.value === lang).label;
};