import { Ref, ref } from 'vue';
import { ComponentExposed } from 'vue-component-type-helpers';
import { FormPropSchema } from './type';
import VForm from './VForm.vue';

interface Methods {
    /**
     * 触发某个按钮操作
     * @param btnId 
     */
    trigger(btnId: string): void;
    validate(): Promise<void>;
}

type UseForm = <T extends object>(props: FormPropSchema<T>) => [
    FormPropSchema<T>,
    Ref<T>,
    Methods,
];

// 表单配置
export const useForm: UseForm = <T extends object>(props: FormPropSchema<T>) => {
    const model = ref({} as T) as Ref<T>;
    const targetRef = ref<ComponentExposed<typeof VForm>>();

    const prop = Object.freeze({
        ...props,
        ref: (curRef: ComponentExposed<typeof VForm>) => {
            targetRef.value = curRef;
        },
    });

    const methods: Methods = {
        trigger(id: string) {
            targetRef.value && targetRef.value.triggerButton(id);
        },
        async validate() {
            targetRef.value && await targetRef.value.validate();
        },
    } as const;

    return [
        prop,
        model,
        methods,
    ];
};

export default {
    useForm,
};