import "core-js/modules/es.array.push.js";
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, unref as _unref, mergeProps as _mergeProps, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue";
import _imports_0 from './images/card-bg.svg';
const _hoisted_1 = {
  class: "login"
};
const _hoisted_2 = {
  class: "box"
};
const _hoisted_3 = {
  class: "card"
};
const _hoisted_4 = {
  class: "left"
};
const _hoisted_5 = {
  class: "form"
};
const _hoisted_6 = ["onClick"];
import { useForm, VForm } from '@/components/form';
import { SchemaFormType } from '@/components/form/type';
import { postAdUserLogin } from '@/service/api';
import { useUserStore } from '@/store/user';
import cookie, { CookitMangerEnum } from '@/utils/cookie';
import { computed, ref, unref } from 'vue';
import { useRouter } from 'vue-router';
export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {
    const router = useRouter();
    const userStore = useUserStore();
    const tenantList = ref([]);
    const showModal = computed({
      get() {
        return (unref(tenantList) || []).length > 0;
      },
      set(value) {
        if (!value) {
          tenantList.value = [];
        }
      }
    });
    const buttonIds = {
      LOGIN: 'LOGIN'
    };
    const [form, model, {
      trigger
    }] = useForm({
      columns: [{
        title: '用户名',
        field: 'username',
        form: {
          type: SchemaFormType.INPUT,
          props: {}
        }
      }, {
        title: '密码',
        field: 'password',
        form: {
          type: SchemaFormType.INPUT,
          props: {
            type: 'password'
          }
        }
      }],
      buttons: [{
        text: '立即登录',
        button: buttonIds.LOGIN,
        handle: model => __awaiter(this, void 0, void 0, function* () {
          const info = yield postAdUserLogin(model);
          if (info.tenantList) {
            tenantList.value = info.tenantList;
            return;
          }
          cookie.set(CookitMangerEnum.SET_USER_TOKEN, info.token);
          yield userStore.loadUserInfo(true);
          router.push('/');
        })
      }]
    });
    const setTenant = id => {
      cookie.set(CookitMangerEnum.SET_TENANT_ID, id);
      trigger(buttonIds.LOGIN);
    };
    return (_ctx, _cache) => {
      const _component_n_card = _resolveComponent("n-card");
      const _component_n_modal = _resolveComponent("n-modal");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_cache[1] || (_cache[1] = _createElementVNode("dl", {
        class: "title"
      }, [_createElementVNode("dt", null, "你好"), _createElementVNode("dd", null, "欢迎使用")], -1)), _createElementVNode("div", _hoisted_5, [_createVNode(_unref(VForm), _mergeProps(_unref(form), {
        value: _unref(model)
      }), null, 16, ["value"])])]), _cache[2] || (_cache[2] = _createElementVNode("div", {
        class: "right"
      }, [_createElementVNode("img", {
        src: _imports_0,
        alt: ""
      })], -1))])]), _createVNode(_component_n_modal, {
        show: showModal.value,
        "onUpdate:show": _cache[0] || (_cache[0] = $event => showModal.value = $event)
      }, {
        default: _withCtx(() => [_createVNode(_component_n_card, {
          title: "选择租户",
          style: {
            "max-width": "800px"
          }
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tenantList.value, tenant => {
            return _openBlock(), _createElementBlock("div", {
              class: "login-item-select",
              onClick: $event => setTenant(tenant.id),
              key: tenant.id
            }, _toDisplayString(tenant.name), 9, _hoisted_6);
          }), 128))]),
          _: 1
        })]),
        _: 1
      }, 8, ["show"])]);
    };
  }
});