var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, isRef as _isRef, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "TemplateActionViewBox"
};
const _hoisted_2 = {
  class: "toolbar"
};
const _hoisted_3 = {
  class: "TemplateActionView"
};
const _hoisted_4 = {
  class: "action"
};
const _hoisted_5 = {
  class: "view"
};
const _hoisted_6 = {
  class: "devices"
};
const _hoisted_7 = ["src"];
const _hoisted_8 = {
  class: "statusbar"
};
import { DEVICE_ENUM } from '@/types/enum';
import { useMessage } from 'naive-ui';
import { computed, onBeforeMount, ref, unref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useElementSize } from '@vueuse/core';
import { usePreviewManger } from '@/hooks/usePreviewManger';
import { useLoading } from '@/hooks/useLoading';
import { getAdTemplateCategoryList, getAdTemplateGet, postAdTemplateAdd, postAdTemplateEdit } from '@/service/api';
import ResourceUploader from '@/components/ResourceUploader.vue';
import { DictEnum, getPromiseDict, renderCurrentDict } from '@/utils/dict';
import ImageUploader from '@/components/ImageUploader.vue';
export default /*@__PURE__*/_defineComponent({
  __name: 'TemplateActionView',
  setup(__props) {
    const router = useRouter();
    const msg = useMessage();
    const [loading, handler] = useLoading();
    // 得到对象
    const iframeRef = ref();
    const {
      message
    } = usePreviewManger(iframeRef);
    const sizeWrapperRef = ref();
    const elSize = useElementSize(sizeWrapperRef);
    const device = ref(DEVICE_ENUM.IPHONE_12_NORMAL.name);
    const loadCategoryList = () => getAdTemplateCategoryList().then(list => {
      return list.map(item => ({
        label: renderCurrentDict(DictEnum.LANGUAGE_TYPE, 'name')(item),
        value: item.id
      }));
    });
    // 选项
    const options = Object.keys(DEVICE_ENUM).map(key => {
      return {
        label: DEVICE_ENUM[key].name,
        value: DEVICE_ENUM[key].name,
        payload: DEVICE_ENUM[key]
      };
    });
    const route = useRoute();
    const id = computed(() => route.params.id);
    const size = computed(() => {
      return options.find(item => item.value === unref(device)).payload;
    });
    // 尺寸比
    const scale = computed(() => {
      if (!unref(elSize.width)) return 0.3;
      const boxWidth = unref(elSize.width) - 40;
      const boxHeight = unref(elSize.height) - 40;
      return Math.min.apply(null, [boxWidth / unref(size).width, boxHeight / unref(size).height]);
    });
    onBeforeMount(() => __awaiter(this, void 0, void 0, function* () {
      if (route.params.id) {
        const info = yield getAdTemplateGet({
          id: route.params.id
        });
        message.value = Object.assign(Object.assign({}, info), {
          resource: JSON.parse(info.resource)
        });
      }
    }));
    const handleSave = () => __awaiter(this, void 0, void 0, function* () {
      yield handler(() => __awaiter(this, void 0, void 0, function* () {
        // 编辑
        if (unref(route).params.id) {
          yield postAdTemplateEdit(Object.assign(Object.assign({}, unref(message)), {
            id: unref(route).params.id,
            resource: JSON.stringify(unref(message).resource)
          }));
        } else {
          yield postAdTemplateAdd(Object.assign(Object.assign({}, unref(message)), {
            resource: JSON.stringify(unref(message).resource)
          }));
        }
        msg.success(`${unref(route).params.id ? '编辑' : '新增'}成功`);
        router.back();
      }));
    });
    return (_ctx, _cache) => {
      const _component_n_button = _resolveComponent("n-button");
      const _component_ApiSelect = _resolveComponent("ApiSelect");
      const _component_n_form_item = _resolveComponent("n-form-item");
      const _component_n_color_picker = _resolveComponent("n-color-picker");
      const _component_n_form = _resolveComponent("n-form");
      const _component_n_select = _resolveComponent("n-select");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_n_button, {
        size: "small",
        type: "primary",
        onClick: handleSave,
        loading: _unref(loading)
      }, {
        default: _withCtx(() => _cache[35] || (_cache[35] = [_createTextVNode("保存模版")])),
        _: 1
      }, 8, ["loading"])]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_n_form, {
        value: _unref(message),
        "onUpdate:value": _cache[33] || (_cache[33] = $event => _isRef(message) ? message.value = $event : null)
      }, {
        default: _withCtx(() => [_createVNode(_component_n_form_item, {
          label: "标题"
        }, {
          default: _withCtx(() => [_createVNode(_component_ApiSelect, {
            value: _unref(message).name,
            "onUpdate:value": _cache[0] || (_cache[0] = $event => _unref(message).name = $event),
            api: _unref(getPromiseDict)(_unref(DictEnum).LANGUAGE_TYPE)
          }, null, 8, ["value", "api"])]),
          _: 1
        }), _createVNode(_component_n_form_item, {
          label: "模版封面"
        }, {
          default: _withCtx(() => [_createVNode(ImageUploader, {
            value: _unref(message).pic,
            "onUpdate:value": _cache[1] || (_cache[1] = $event => _unref(message).pic = $event)
          }, null, 8, ["value"])]),
          _: 1
        }), _createVNode(_component_n_form_item, {
          label: "模版分类"
        }, {
          default: _withCtx(() => [_createVNode(_component_ApiSelect, {
            value: _unref(message).categoryId,
            "onUpdate:value": _cache[2] || (_cache[2] = $event => _unref(message).categoryId = $event),
            api: loadCategoryList,
            setFirst: true
          }, null, 8, ["value"])]),
          _: 1
        }), _createVNode(_component_n_form_item, {
          label: "是否vip模版"
        }, {
          default: _withCtx(() => [_createVNode(_component_ApiSelect, {
            value: _unref(message).isVip,
            "onUpdate:value": _cache[3] || (_cache[3] = $event => _unref(message).isVip = $event),
            api: _unref(getPromiseDict)(_unref(DictEnum).IS_VIP_OPTION),
            setFirst: true
          }, null, 8, ["value", "api"])]),
          _: 1
        }), _createVNode(_component_n_form_item, {
          label: "是否存在装饰"
        }, {
          default: _withCtx(() => [_createVNode(_component_ApiSelect, {
            value: _unref(message).decoration,
            "onUpdate:value": _cache[4] || (_cache[4] = $event => _unref(message).decoration = $event),
            api: _unref(getPromiseDict)(_unref(DictEnum).IS_INCLUDE_DECORATION),
            setFirst: true
          }, null, 8, ["value", "api"])]),
          _: 1
        }), _createVNode(_component_n_form_item, {
          label: "上架状态"
        }, {
          default: _withCtx(() => [_createVNode(_component_ApiSelect, {
            value: _unref(message).status,
            "onUpdate:value": _cache[5] || (_cache[5] = $event => _unref(message).status = $event),
            api: _unref(getPromiseDict)(_unref(DictEnum).API_GET_STATUS)
          }, null, 8, ["value", "api"])]),
          _: 1
        }), _createVNode(_component_n_form_item, {
          label: "状态栏颜色"
        }, {
          default: _withCtx(() => [_createVNode(_component_n_color_picker, {
            value: _unref(message).statusColor,
            "onUpdate:value": _cache[6] || (_cache[6] = $event => _unref(message).statusColor = $event),
            "show-alpha": false
          }, null, 8, ["value"])]),
          _: 1
        }), _createVNode(_component_n_form_item, {
          label: "主题色"
        }, {
          default: _withCtx(() => [_createVNode(_component_n_color_picker, {
            value: _unref(message).themeColor,
            "onUpdate:value": _cache[7] || (_cache[7] = $event => _unref(message).themeColor = $event),
            "show-alpha": false
          }, null, 8, ["value"])]),
          _: 1
        }), _createVNode(_component_n_form_item, {
          label: "结果展示色"
        }, {
          default: _withCtx(() => [_createVNode(_component_n_color_picker, {
            value: _unref(message).twoColor,
            "onUpdate:value": _cache[8] || (_cache[8] = $event => _unref(message).twoColor = $event),
            "show-alpha": false
          }, null, 8, ["value"])]),
          _: 1
        }), _createVNode(_component_n_form_item, {
          label: "二级页面背景"
        }, {
          default: _withCtx(() => [_createVNode(_component_n_color_picker, {
            value: _unref(message).twoBgColor,
            "onUpdate:value": _cache[9] || (_cache[9] = $event => _unref(message).twoBgColor = $event),
            "show-alpha": false
          }, null, 8, ["value"])]),
          _: 1
        }), _createVNode(_component_n_form_item, {
          label: "背景相关"
        }, {
          default: _withCtx(() => [_createVNode(ResourceUploader, {
            value: _unref(message).resource,
            "onUpdate:value": _cache[10] || (_cache[10] = $event => _unref(message).resource = $event),
            options: [{
              label: '整体背景',
              value: 'bg'
            }, {
              label: '头部背景',
              value: 'top_bg'
            }, {
              label: '头部背景(IPad)',
              value: 'top_bg_1'
            }, {
              label: '键盘背景',
              value: 'f_bg'
            }, {
              label: '键盘背景(IPad)',
              value: 'f_bg_1'
            }]
          }, null, 8, ["value"])]),
          _: 1
        }), _createVNode(_component_n_form_item, {
          label: "头部按钮(换肤)"
        }, {
          default: _withCtx(() => [_createVNode(ResourceUploader, {
            value: _unref(message).resource,
            "onUpdate:value": _cache[11] || (_cache[11] = $event => _unref(message).resource = $event),
            options: [{
              label: '初始状态',
              value: 't_l_1'
            }, {
              label: '按下状态',
              value: 't_l_2'
            }]
          }, null, 8, ["value"])]),
          _: 1
        }), _createVNode(_component_n_form_item, {
          label: "头部按钮(存档)"
        }, {
          default: _withCtx(() => [_createVNode(ResourceUploader, {
            value: _unref(message).resource,
            "onUpdate:value": _cache[12] || (_cache[12] = $event => _unref(message).resource = $event),
            options: [{
              label: '初始状态',
              value: 't_c_1'
            }, {
              label: '按下状态',
              value: 't_c_2'
            }]
          }, null, 8, ["value"])]),
          _: 1
        }), _createVNode(_component_n_form_item, {
          label: "头部按钮(设置)"
        }, {
          default: _withCtx(() => [_createVNode(ResourceUploader, {
            value: _unref(message).resource,
            "onUpdate:value": _cache[13] || (_cache[13] = $event => _unref(message).resource = $event),
            options: [{
              label: '初始状态',
              value: 't_s_1'
            }, {
              label: '按下状态',
              value: 't_s_2'
            }]
          }, null, 8, ["value"])]),
          _: 1
        }), _createVNode(_component_n_form_item, {
          label: "键盘（C）"
        }, {
          default: _withCtx(() => [_createVNode(ResourceUploader, {
            value: _unref(message).resource,
            "onUpdate:value": _cache[14] || (_cache[14] = $event => _unref(message).resource = $event),
            options: [{
              label: '初始状态',
              value: 'c_1'
            }, {
              label: '按下状态',
              value: 'c_2'
            }]
          }, null, 8, ["value"])]),
          _: 1
        }), _createVNode(_component_n_form_item, {
          label: "键盘（除）"
        }, {
          default: _withCtx(() => [_createVNode(ResourceUploader, {
            value: _unref(message).resource,
            "onUpdate:value": _cache[15] || (_cache[15] = $event => _unref(message).resource = $event),
            options: [{
              label: '初始状态',
              value: 'chu_1'
            }, {
              label: '按下状态',
              value: 'chu_2'
            }]
          }, null, 8, ["value"])]),
          _: 1
        }), _createVNode(_component_n_form_item, {
          label: "键盘（乘）"
        }, {
          default: _withCtx(() => [_createVNode(ResourceUploader, {
            value: _unref(message).resource,
            "onUpdate:value": _cache[16] || (_cache[16] = $event => _unref(message).resource = $event),
            options: [{
              label: '初始状态',
              value: 'x_1'
            }, {
              label: '按下状态',
              value: 'x_2'
            }]
          }, null, 8, ["value"])]),
          _: 1
        }), _createVNode(_component_n_form_item, {
          label: "键盘（回退）"
        }, {
          default: _withCtx(() => [_createVNode(ResourceUploader, {
            value: _unref(message).resource,
            "onUpdate:value": _cache[17] || (_cache[17] = $event => _unref(message).resource = $event),
            options: [{
              label: '初始状态',
              value: 'delete_1'
            }, {
              label: '按下状态',
              value: 'delete_2'
            }]
          }, null, 8, ["value"])]),
          _: 1
        }), _createVNode(_component_n_form_item, {
          label: "键盘（加）"
        }, {
          default: _withCtx(() => [_createVNode(ResourceUploader, {
            value: _unref(message).resource,
            "onUpdate:value": _cache[18] || (_cache[18] = $event => _unref(message).resource = $event),
            options: [{
              label: '初始状态',
              value: 'jia_1'
            }, {
              label: '按下状态',
              value: 'jia_2'
            }]
          }, null, 8, ["value"])]),
          _: 1
        }), _createVNode(_component_n_form_item, {
          label: "键盘（减）"
        }, {
          default: _withCtx(() => [_createVNode(ResourceUploader, {
            value: _unref(message).resource,
            "onUpdate:value": _cache[19] || (_cache[19] = $event => _unref(message).resource = $event),
            options: [{
              label: '初始状态',
              value: 'jian_1'
            }, {
              label: '按下状态',
              value: 'jian_2'
            }]
          }, null, 8, ["value"])]),
          _: 1
        }), _createVNode(_component_n_form_item, {
          label: "键盘（等于）"
        }, {
          default: _withCtx(() => [_createVNode(ResourceUploader, {
            value: _unref(message).resource,
            "onUpdate:value": _cache[20] || (_cache[20] = $event => _unref(message).resource = $event),
            options: [{
              label: '初始状态',
              value: 'eq_1'
            }, {
              label: '按下状态',
              value: 'eq_2'
            }]
          }, null, 8, ["value"])]),
          _: 1
        }), _createVNode(_component_n_form_item, {
          label: "键盘（更多）"
        }, {
          default: _withCtx(() => [_createVNode(ResourceUploader, {
            value: _unref(message).resource,
            "onUpdate:value": _cache[21] || (_cache[21] = $event => _unref(message).resource = $event),
            options: [{
              label: '初始状态',
              value: 'm_1'
            }, {
              label: '按下状态',
              value: 'm_2'
            }]
          }, null, 8, ["value"])]),
          _: 1
        }), _createVNode(_component_n_form_item, {
          label: "键盘（小数点）"
        }, {
          default: _withCtx(() => [_createVNode(ResourceUploader, {
            value: _unref(message).resource,
            "onUpdate:value": _cache[22] || (_cache[22] = $event => _unref(message).resource = $event),
            options: [{
              label: '初始状态',
              value: 'd_1'
            }, {
              label: '按下状态',
              value: 'd_2'
            }]
          }, null, 8, ["value"])]),
          _: 1
        }), _createVNode(_component_n_form_item, {
          label: "键盘（0）"
        }, {
          default: _withCtx(() => [_createVNode(ResourceUploader, {
            value: _unref(message).resource,
            "onUpdate:value": _cache[23] || (_cache[23] = $event => _unref(message).resource = $event),
            options: [{
              label: '初始状态',
              value: 'n0_1'
            }, {
              label: '按下状态',
              value: 'n0_2'
            }]
          }, null, 8, ["value"])]),
          _: 1
        }), _createVNode(_component_n_form_item, {
          label: "键盘（1）"
        }, {
          default: _withCtx(() => [_createVNode(ResourceUploader, {
            value: _unref(message).resource,
            "onUpdate:value": _cache[24] || (_cache[24] = $event => _unref(message).resource = $event),
            options: [{
              label: '初始状态',
              value: 'n1_1'
            }, {
              label: '按下状态',
              value: 'n1_2'
            }]
          }, null, 8, ["value"])]),
          _: 1
        }), _createVNode(_component_n_form_item, {
          label: "键盘（2）"
        }, {
          default: _withCtx(() => [_createVNode(ResourceUploader, {
            value: _unref(message).resource,
            "onUpdate:value": _cache[25] || (_cache[25] = $event => _unref(message).resource = $event),
            options: [{
              label: '初始状态',
              value: 'n2_1'
            }, {
              label: '按下状态',
              value: 'n2_2'
            }]
          }, null, 8, ["value"])]),
          _: 1
        }), _createVNode(_component_n_form_item, {
          label: "键盘（3）"
        }, {
          default: _withCtx(() => [_createVNode(ResourceUploader, {
            value: _unref(message).resource,
            "onUpdate:value": _cache[26] || (_cache[26] = $event => _unref(message).resource = $event),
            options: [{
              label: '初始状态',
              value: 'n3_1'
            }, {
              label: '按下状态',
              value: 'n3_2'
            }]
          }, null, 8, ["value"])]),
          _: 1
        }), _createVNode(_component_n_form_item, {
          label: "键盘（4）"
        }, {
          default: _withCtx(() => [_createVNode(ResourceUploader, {
            value: _unref(message).resource,
            "onUpdate:value": _cache[27] || (_cache[27] = $event => _unref(message).resource = $event),
            options: [{
              label: '初始状态',
              value: 'n4_1'
            }, {
              label: '按下状态',
              value: 'n4_2'
            }]
          }, null, 8, ["value"])]),
          _: 1
        }), _createVNode(_component_n_form_item, {
          label: "键盘（5）"
        }, {
          default: _withCtx(() => [_createVNode(ResourceUploader, {
            value: _unref(message).resource,
            "onUpdate:value": _cache[28] || (_cache[28] = $event => _unref(message).resource = $event),
            options: [{
              label: '初始状态',
              value: 'n5_1'
            }, {
              label: '按下状态',
              value: 'n5_2'
            }]
          }, null, 8, ["value"])]),
          _: 1
        }), _createVNode(_component_n_form_item, {
          label: "键盘（6）"
        }, {
          default: _withCtx(() => [_createVNode(ResourceUploader, {
            value: _unref(message).resource,
            "onUpdate:value": _cache[29] || (_cache[29] = $event => _unref(message).resource = $event),
            options: [{
              label: '初始状态',
              value: 'n6_1'
            }, {
              label: '按下状态',
              value: 'n6_2'
            }]
          }, null, 8, ["value"])]),
          _: 1
        }), _createVNode(_component_n_form_item, {
          label: "键盘（7）"
        }, {
          default: _withCtx(() => [_createVNode(ResourceUploader, {
            value: _unref(message).resource,
            "onUpdate:value": _cache[30] || (_cache[30] = $event => _unref(message).resource = $event),
            options: [{
              label: '初始状态',
              value: 'n7_1'
            }, {
              label: '按下状态',
              value: 'n7_2'
            }]
          }, null, 8, ["value"])]),
          _: 1
        }), _createVNode(_component_n_form_item, {
          label: "键盘（8）"
        }, {
          default: _withCtx(() => [_createVNode(ResourceUploader, {
            value: _unref(message).resource,
            "onUpdate:value": _cache[31] || (_cache[31] = $event => _unref(message).resource = $event),
            options: [{
              label: '初始状态',
              value: 'n8_1'
            }, {
              label: '按下状态',
              value: 'n8_2'
            }]
          }, null, 8, ["value"])]),
          _: 1
        }), _createVNode(_component_n_form_item, {
          label: "键盘（9）"
        }, {
          default: _withCtx(() => [_createVNode(ResourceUploader, {
            value: _unref(message).resource,
            "onUpdate:value": _cache[32] || (_cache[32] = $event => _unref(message).resource = $event),
            options: [{
              label: '初始状态',
              value: 'n9_1'
            }, {
              label: '按下状态',
              value: 'n9_2'
            }]
          }, null, 8, ["value"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["value"])]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createVNode(_component_n_select, {
        block: "",
        options: _unref(options),
        placeholder: "选择设备型号",
        value: device.value,
        "onUpdate:value": _cache[34] || (_cache[34] = $event => device.value = $event),
        clearable: false
      }, null, 8, ["options", "value"])]), _createElementVNode("div", {
        class: "flex",
        ref_key: "sizeWrapperRef",
        ref: sizeWrapperRef
      }, [_createElementVNode("div", {
        class: "size",
        style: _normalizeStyle({
          width: `${size.value.width}px`,
          height: `${size.value.height}px`,
          transform: `translateX(-50%) translateY(-50%) scale(${scale.value})`
        })
      }, [_createElementVNode("iframe", {
        ref_key: "iframeRef",
        ref: iframeRef,
        title: "预览",
        src: `/preview/${id.value}?isPad=${size.value.isPad}`,
        width: "100%",
        height: "100%",
        frameborder: "0"
      }, null, 8, _hoisted_7), _createElementVNode("div", _hoisted_8, [_createElementVNode("div", {
        class: "time",
        style: _normalizeStyle({
          color: _unref(message).statusColor
        })
      }, "2024-11-11 10:00", 4), _cache[36] || (_cache[36] = _createElementVNode("div", {
        class: "icons"
      }, null, -1))])], 4)], 512)])])]);
    };
  }
});