var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { computed, unref, ref } from 'vue';
import { useForm } from '@/components/form';
import { useLoading } from '@/hooks/useLoading';
import { SchemaFormType } from '@/components/form/type';
import { isFunction } from 'lodash-es';
export default /*@__PURE__*/_defineComponent({
  __name: 'AddChildModal',
  props: {
    record: {
      type: Object
    },
    submit: Function
  },
  emits: ['update:record'],
  setup(__props, {
    emit: __emit
  }) {
    const emit = __emit;
    const [loading, handler] = useLoading();
    const model = ref({});
    const [editForm] = useForm({
      columns: [{
        field: 'label',
        title: '字段名称',
        form: {
          type: SchemaFormType.INPUT
        }
      }, {
        field: 'value',
        title: '字段值',
        form: {
          type: SchemaFormType.INPUT
        }
      }],
      buttons: []
    });
    const props = __props;
    const visible = computed({
      get() {
        return !!unref(props.record);
      },
      set(visible) {
        if (!visible) {
          emit('update:record', undefined);
        }
      }
    });
    const handleSubmit = () => {
      return handler(() => __awaiter(this, void 0, void 0, function* () {
        const record = Object.assign(Object.assign({}, unref(model) || {}), {
          type: '',
          pid: unref(props.record).id
        });
        if (isFunction(props.submit)) {
          props.submit(record);
        }
      }));
    };
    const handleCancel = () => {
      visible.value = false;
    };
    return (_ctx, _cache) => {
      const _component_VForm = _resolveComponent("VForm");
      const _component_n_button = _resolveComponent("n-button");
      const _component_n_space = _resolveComponent("n-space");
      const _component_n_card = _resolveComponent("n-card");
      const _component_n_modal = _resolveComponent("n-modal");
      return _openBlock(), _createBlock(_component_n_modal, {
        show: visible.value,
        "onUpdate:show": _cache[1] || (_cache[1] = $event => visible.value = $event)
      }, {
        default: _withCtx(() => [_createVNode(_component_n_card, {
          style: {
            "max-width": "800px"
          },
          title: "添加下级",
          bordered: false,
          size: "small",
          role: "dialog",
          "aria-modal": "true"
        }, {
          footer: _withCtx(() => [_createVNode(_component_n_space, null, {
            default: _withCtx(() => [_createVNode(_component_n_button, {
              type: "primary",
              onClick: handleSubmit,
              loading: _unref(loading)
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [_createTextVNode("确认")])),
              _: 1
            }, 8, ["loading"]), _createVNode(_component_n_button, {
              onClick: handleCancel,
              loading: _unref(loading)
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [_createTextVNode("取消")])),
              _: 1
            }, 8, ["loading"])]),
            _: 1
          })]),
          default: _withCtx(() => [_createVNode(_component_VForm, _mergeProps(_unref(editForm), {
            value: model.value,
            "onUpdate:value": _cache[0] || (_cache[0] = $event => model.value = $event)
          }), null, 16, ["value"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["show"]);
    };
  }
});