import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["src"];
const _hoisted_2 = ["src"];
import { onBeforeMount, onUnmounted, ref } from 'vue';
export default /*@__PURE__*/_defineComponent({
  __name: 'IconButton',
  props: {
    isIpad: Boolean,
    normal: {
      type: String,
      default: ''
    },
    down: {
      type: String,
      default: ''
    }
  },
  setup(__props) {
    const isDown = ref(false);
    const handleUp = () => {
      isDown.value = false;
    };
    const handleDown = () => {
      isDown.value = true;
    };
    onBeforeMount(() => {
      window.addEventListener('mouseup', handleUp);
      window.addEventListener('touchend', handleUp);
    });
    onUnmounted(() => {
      window.removeEventListener('mouseup', handleUp);
      window.removeEventListener('touchend', handleUp);
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["IconButton", {
          'down': isDown.value,
          'isIpad': __props.isIpad
        }]),
        onMousedownPassive: handleDown,
        onTouchstartPassive: handleDown
      }, [_createElementVNode("img", {
        alt: "",
        src: __props.normal,
        class: "normal"
      }, null, 8, _hoisted_1), _createElementVNode("img", {
        alt: "",
        src: __props.down,
        class: "down"
      }, null, 8, _hoisted_2)], 34);
    };
  }
});