
export const fileToBlob = (file: File) => {
    return new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = (e) => {
            resolve(new Blob([e.target.result]));
        };
    });
};
