import "core-js/modules/es.array.push.js";
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, isRef as _isRef, mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
  class: "table"
};
const _hoisted_2 = {
  key: 0,
  class: "form-box"
};
const _hoisted_3 = {
  class: "action-box"
};
const _hoisted_4 = {
  class: "table-box"
};
import { computed, unref, onMounted, ref, nextTick } from 'vue';
import { useLoading } from '@/hooks/useLoading';
import { debounce, isArray } from 'lodash-es';
import { InlineForm, VForm } from '../form';
import { useForm } from '../form/useForm';
export default /*@__PURE__*/_defineComponent({
  __name: 'Table',
  props: {
    tree: {
      // eslint-disable-next-line no-undef
      type: Object,
      default: () => ({})
    },
    api: {
      type: [Function, null]
    },
    add: {
      type: [Function, null],
      default: null
    },
    edit: {
      type: [Function, null],
      default: null
    },
    remove: {
      type: [Function, null],
      default: null
    },
    search: {
      type: Object,
      default: () => ({})
    },
    form: {
      type: Object,
      default: () => ({})
    },
    pagination: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Array,
      default: () => []
    }
  },
  emits: ['update:data', 'update:pagination'],
  setup(__props, {
    emit: __emit
  }) {
    const emit = __emit;
    const props = __props;
    const showSearch = computed(() => {
      return !!unref(props.add) || !!unref(props.columns).find(col => col.search);
    });
    const action = ref(false);
    const isAdd = ref(true);
    const [loading, handler] = useLoading();
    const remove = model => __awaiter(this, void 0, void 0, function* () {
      yield handler(() => __awaiter(this, void 0, void 0, function* () {
        if (!unref(props.remove) || !model.id) return;
        yield unref(props.remove)(model);
      }));
      // eslint-disable-next-line no-use-before-define
      yield onLoadData();
    });
    const edit = model => __awaiter(this, void 0, void 0, function* () {
      // eslint-disable-next-line no-use-before-define
      editModel.value = JSON.parse(JSON.stringify(model));
      // eslint-disable-next-line no-use-before-define
      isAdd.value = false;
      action.value = true;
    });
    const add = model => __awaiter(this, void 0, void 0, function* () {
      // eslint-disable-next-line no-use-before-define
      editModel.value = JSON.parse(JSON.stringify(model || {}));
      // eslint-disable-next-line no-use-before-define
      isAdd.value = false;
      action.value = true;
    });
    const cols = computed(() => unref(props.columns).map(col => {
      return {
        title: col.title,
        key: col.field,
        width: col.width || (col.field === 'action' ? 200 : undefined),
        fixed: col.field === 'action' ? 'right' : col.fixed,
        ellipsis: {
          tooltip: true
        },
        render: col.render ? record => col.render(record, {
          handler,
          remove,
          edit,
          add
        }) : null
      };
    }));
    const appPagination = ref({
      page: 1,
      pageSize: 50,
      pageSizes: [10, 30, 50, 100, 200],
      showSizePicker: true,
      onChange(page) {
        appPagination.value.page = page;
        // eslint-disable-next-line no-use-before-define
        nextTick(onLoadData);
      },
      onUpdatePageSize: pageSize => {
        appPagination.value.pageSize = pageSize;
        appPagination.value.page = 1;
      }
    });
    const handle = model => __awaiter(this, void 0, void 0, function* () {
      if (!unref(props.api)) return;
      const info = yield unref(props.api)(!props.pagination ? Object.assign(Object.assign({}, model), {
        pageIndex: undefined,
        pageSize: undefined
      }) : Object.assign(Object.assign({}, model), {
        pageIndex: unref(appPagination).page,
        pageSize: unref(appPagination).pageSize
      }));
      if (isArray(info)) {
        emit('update:data', info);
        return;
      }
      emit('update:data', info.records || []);
    });
    const handleAdd = () => __awaiter(this, void 0, void 0, function* () {
      if (!unref(props.add)) return;
      action.value = true;
      isAdd.value = true;
    });
    const buttons = [{
      text: '查询',
      button: 'search',
      reload: true,
      handle
    }];
    if (unref(props.add)) {
      buttons.push({
        text: '新增',
        button: 'add',
        reload: false,
        handle: handleAdd
      });
    }
    const [searchProps, searchModel, {
      trigger
    }] = useForm({
      columns: unref(props).columns.filter(col => !!col.search).map(col => {
        return Object.assign(Object.assign({}, col), {
          form: col.search
        });
      }),
      buttons
    });
    const [editForm, editModel, method] = useForm({
      columns: unref(props).columns.filter(col => !!col.form).map(col => {
        return Object.assign(Object.assign({}, col), {
          form: col.form
        });
      }),
      buttons: []
    });
    const onLoadData = debounce(() => __awaiter(this, void 0, void 0, function* () {
      if (unref(showSearch)) {
        trigger('search');
      }
      yield handler(() => __awaiter(this, void 0, void 0, function* () {
        yield handle(unref(searchModel));
      }));
    }));
    const handleSubmit = () => __awaiter(this, void 0, void 0, function* () {
      if (unref(isAdd)) {
        yield handler(() => __awaiter(this, void 0, void 0, function* () {
          if (!unref(props.add)) return;
          yield method.validate();
          yield unref(props.add)(unref(editModel));
        }));
      } else {
        yield handler(() => __awaiter(this, void 0, void 0, function* () {
          if (!unref(props.edit)) return;
          yield method.validate();
          yield unref(props.edit)(unref(editModel));
        }));
      }
      yield onLoadData();
      action.value = false;
    });
    const handleCancel = () => {
      editModel.value = {};
      action.value = false;
      isAdd.value = false;
    };
    onMounted(onLoadData);
    //  最终数据
    const currentData = computed(() => {
      var _a;
      const field = String(((_a = unref(props.tree)) === null || _a === void 0 ? void 0 : _a.field) || '');
      if (!field) return unref(props.data);
      const map = new Map();
      for (const item of unref(props.data)) {
        const fieldId = item[field];
        if (fieldId) {
          const list = map.get(String(fieldId)) || [];
          list.push(item);
          map.set(String(fieldId), list);
        }
      }
      const getTreeData = list => {
        return list.map(item => {
          return Object.assign(Object.assign({}, item), {
            children: getTreeData(map.get(String(item.id)) || [])
          });
        });
      };
      return getTreeData(unref(props.data).filter(item => !map.get(String(item[field]))));
    });
    return (_ctx, _cache) => {
      var _a, _b, _c;
      const _component_n_data_table = _resolveComponent("n-data-table");
      const _component_n_button = _resolveComponent("n-button");
      const _component_n_space = _resolveComponent("n-space");
      const _component_n_card = _resolveComponent("n-card");
      const _component_n_modal = _resolveComponent("n-modal");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [showSearch.value ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_unref(InlineForm), _mergeProps(_unref(searchProps), {
        value: _unref(searchModel),
        "onUpdate:value": _cache[0] || (_cache[0] = $event => _isRef(searchModel) ? searchModel.value = $event : null)
      }), null, 16, ["value"])])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_3, [_renderSlot(_ctx.$slots, "toolbar")]), _renderSlot(_ctx.$slots, "default"), _createElementVNode("div", _hoisted_4, [(_openBlock(), _createBlock(_component_n_data_table, {
        striped: true,
        "flex-height": true,
        key: currentData.value.length,
        indent: ((_a = __props.tree) === null || _a === void 0 ? void 0 : _a.indent) || 0,
        "default-expand-all": Boolean((_b = __props.tree) === null || _b === void 0 ? void 0 : _b.defaultExpandAll),
        tree: Boolean((_c = __props.tree) === null || _c === void 0 ? void 0 : _c.field),
        loading: _unref(loading),
        bordered: true,
        size: "small",
        columns: cols.value,
        data: currentData.value,
        pagination: __props.pagination ? appPagination.value : false
      }, null, 8, ["indent", "default-expand-all", "tree", "loading", "columns", "data", "pagination"]))]), _createVNode(_component_n_modal, {
        show: action.value,
        "onUpdate:show": _cache[2] || (_cache[2] = $event => action.value = $event)
      }, {
        default: _withCtx(() => [_createVNode(_component_n_card, {
          style: {
            "max-width": "800px"
          },
          title: isAdd.value ? '新增' : '编辑',
          bordered: false,
          size: "small",
          role: "dialog",
          "aria-modal": "true"
        }, {
          footer: _withCtx(() => [_createVNode(_component_n_space, null, {
            default: _withCtx(() => [_createVNode(_component_n_button, {
              type: "primary",
              onClick: handleSubmit,
              loading: _unref(loading)
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [_createTextVNode("确认")])),
              _: 1
            }, 8, ["loading"]), _createVNode(_component_n_button, {
              onClick: handleCancel,
              loading: _unref(loading)
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [_createTextVNode("取消")])),
              _: 1
            }, 8, ["loading"])]),
            _: 1
          })]),
          default: _withCtx(() => [_createVNode(_unref(VForm), _mergeProps(_unref(editForm), {
            value: _unref(editModel),
            "onUpdate:value": _cache[1] || (_cache[1] = $event => _isRef(editModel) ? editModel.value = $event : null)
          }), null, 16, ["value"])]),
          _: 1
        }, 8, ["title"])]),
        _: 1
      }, 8, ["show"])]);
    };
  }
});