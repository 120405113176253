import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import { useUserStore } from './store/user';
import { getAllDictList } from './utils/dict';

async function main() {
    const pinia = createPinia();
    const app = createApp(App);
    app.use(router);
    app.use(pinia);
    const store = useUserStore();

    router.beforeEach(async (to) => {
        const { fullPath } = to;
        if (!fullPath.startsWith('/login')) {
            await store.loadUserInfo();
            await getAllDictList();
        }
        if (!store.userInfo.userIdMd5 && !fullPath.startsWith('/login')) {
            router.replace(`/login?origin=${fullPath}`);
        }
    });


    app.mount('#app');
}

main();