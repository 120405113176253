import { PropType, Reactive } from 'vue';
import { FormPropSchema } from './type';

type Prop<CurrentValue extends object> = FormPropSchema<CurrentValue> & {
    value: Reactive<CurrentValue>
};

export const useProps = <CurrentValue extends object>() => {
    return {
        size: {
            type: String as PropType<Prop<CurrentValue>['size']>,
            default: 'small',
        },
        columns: {
            type: Array as PropType<Prop<CurrentValue>['columns']>,
            default: () => [],
        },
        buttons: {
            type: Array as PropType<Prop<CurrentValue>['buttons']>,
            default: () => [],
        },
        value: {
            type: Object as PropType<Reactive<any>>,
            default: () => ({}),
        },
    };
};