import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["src"];
const _hoisted_2 = ["src"];
import { onBeforeMount, onUnmounted, ref, unref } from 'vue';
export default /*@__PURE__*/_defineComponent({
  __name: 'BoardButton',
  props: {
    width: Number,
    height: Number,
    doubleHeight: Number,
    value: String,
    pos: {
      type: Array,
      default: () => [0, 0]
    },
    normal: {
      type: String,
      default: ''
    },
    down: {
      type: String,
      default: ''
    },
    handleKeyDown: {
      type: Function,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      default: () => {}
    }
  },
  setup(__props) {
    const isDown = ref(false);
    const props = __props;
    const handleUp = () => {
      isDown.value = false;
    };
    const handleDown = () => {
      isDown.value = true;
      unref(props.handleKeyDown)(unref(props.value));
    };
    onBeforeMount(() => {
      window.addEventListener('mouseup', handleUp);
      window.addEventListener('touchend', handleUp);
    });
    onUnmounted(() => {
      window.removeEventListener('mouseup', handleUp);
      window.removeEventListener('touchend', handleUp);
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["BoardButton", {
          'down': isDown.value
        }]),
        style: _normalizeStyle({
          width: `${__props.width / 100}rem`,
          height: `${(__props.doubleHeight || __props.height) / 100}rem`,
          left: `${__props.pos[0] * __props.width / 100}rem`,
          top: `${__props.pos[1] * __props.height / 100}rem`
        }),
        onMousedownPassive: handleDown,
        onTouchstartPassive: handleDown
      }, [_createElementVNode("img", {
        alt: "normal",
        src: __props.normal,
        class: "normal"
      }, null, 8, _hoisted_1), _createElementVNode("img", {
        alt: "down",
        src: __props.down,
        class: "down"
      }, null, 8, _hoisted_2)], 38);
    };
  }
});