import { NInput, NInputNumber } from 'naive-ui';
import ApiSelect from '../ApiSelect.vue';
import FileUpload from '../FileUpload.vue';

import { SchemaFormType } from './type';

export const componentMap = {
    [SchemaFormType.INPUT]: NInput,
    [SchemaFormType.INPUT_NUMBER]: NInputNumber,
    [SchemaFormType.SELECT]: ApiSelect,
    [SchemaFormType.FILE_UPLOAD]: FileUpload,
} as const;