import { defineComponent as _defineComponent, createVNode as _createVNode, createTextVNode as _createTextVNode } from 'vue';
import { unref as _unref, isRef as _isRef, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { SchemaFormType } from '@/components/form/type';
import { Table, useTable } from '@/components/table';
import { getAdOrderTemplateList, postAdOrderTemplateAdd, postAdOrderTemplateEdit, postAdOrderTemplateRemove } from '@/service/api';
import { DictEnum, getPromiseDict, renderCurrentDict } from '@/utils/dict';
import { NButton, NPopconfirm, NSpace, NTag } from 'naive-ui';
export default /*@__PURE__*/_defineComponent({
  __name: 'OrderTemplateView',
  setup(__props) {
    const [table, data] = useTable({
      api: getAdOrderTemplateList,
      add: postAdOrderTemplateAdd,
      edit: postAdOrderTemplateEdit,
      remove: postAdOrderTemplateRemove,
      columns: [{
        field: 'id',
        title: 'id'
      }, {
        field: 'name',
        title: '充值模版名称',
        form: {
          type: SchemaFormType.SELECT,
          itemProps: {
            rule: [{
              required: true,
              message: '请输入',
              type: 'string'
            }]
          },
          props: {
            api: getPromiseDict(DictEnum.LANGUAGE_TYPE),
            clearable: false
          }
        },
        render: renderCurrentDict(DictEnum.LANGUAGE_TYPE, 'name')
      }, {
        field: 'tips',
        title: '充值模版标签',
        form: {
          type: SchemaFormType.SELECT,
          props: {
            api: getPromiseDict(DictEnum.LANGUAGE_TYPE),
            clearable: true
          }
        },
        render: renderCurrentDict(DictEnum.LANGUAGE_TYPE, 'tips')
      }, {
        field: 'isSandBox',
        title: '是否测试模版',
        form: {
          type: SchemaFormType.SELECT,
          props: {
            api: getPromiseDict(DictEnum.IS_SAND_BOX)
          },
          itemProps: {
            rule: [{
              required: true,
              message: '请选择',
              type: 'number'
            }]
          }
        },
        render: model => _createVNode(NTag, {
          "type": model.isSandBox ? 'success' : 'error'
        }, {
          default: () => [model.isSandBox ? '是' : '否']
        })
      }, {
        field: 'isMonthly',
        title: '是否连续包月',
        form: {
          type: SchemaFormType.SELECT,
          props: {
            api: getPromiseDict(DictEnum.IS_MONTH_ALL_IN)
          },
          itemProps: {
            rule: [{
              required: true,
              message: '请选择',
              type: 'number'
            }]
          }
        },
        render: model => _createVNode(NTag, {
          "type": model.isMonthly ? 'success' : 'error'
        }, {
          default: () => [model.isMonthly ? '是' : '否']
        })
      }, {
        field: 'day',
        title: '增加会员天数',
        form: {
          type: SchemaFormType.INPUT_NUMBER,
          itemProps: {
            rule: [{
              required: true,
              message: '请输入',
              type: 'number'
            }]
          }
        }
      }, {
        field: 'money',
        title: '档位金额(分)',
        form: {
          type: SchemaFormType.INPUT_NUMBER,
          itemProps: {
            rule: [{
              required: true,
              message: '请输入',
              type: 'number'
            }]
          }
        }
      }, {
        field: 'sort',
        title: '排序',
        form: {
          type: SchemaFormType.INPUT_NUMBER,
          itemProps: {
            rule: [{
              required: true,
              message: '请输入',
              type: 'number'
            }]
          }
        }
      }, {
        field: 'action',
        title: '操作',
        width: 100,
        fixed: 'right',
        render(model, action) {
          return _createVNode(NSpace, {
            "size": 'small'
          }, {
            default: () => [_createVNode(NButton, {
              "size": 'tiny',
              "type": "info",
              "onClick": () => action.edit(model)
            }, {
              default: () => [_createTextVNode("\u7F16\u8F91")]
            }), _createVNode(NPopconfirm, {
              "on-positive-click": () => action.remove(model),
              "negative-text": "\u53D6\u6D88",
              "positive-text": "\u786E\u8BA4"
            }, {
              default: () => [_createTextVNode("\u4F60\u786E\u5B9A\u8981\u5220\u9664\uFF1F")],
              trigger: () => _createVNode(NButton, {
                "size": 'tiny',
                "type": "error"
              }, {
                default: () => [_createTextVNode("\u5220\u9664")]
              })
            })]
          });
        }
      }]
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createBlock(_unref(Table), _mergeProps(_unref(table), {
        data: _unref(data),
        "onUpdate:data": _cache[0] || (_cache[0] = $event => _isRef(data) ? data.value = $event : null)
      }), null, 16, ["data"]);
    };
  }
});